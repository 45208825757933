








import Vue from "vue";
import DataContainer from "@vuetifyx/common/DataContainer";
import coreApiClient from "@/services/apis/coreApiClient";
import { timeZones } from "@/constants/setting";
import userManager from "@/services/userManager";
import Loading from "@/components/Loading.vue";
import BlurredTenant from "@/components/BlurredTenant.vue";

function nornalizeTimeZone(timezone) {
  const numStr = timezone.utc.replace("UTC", "").replace(":", ".").replace("±", "");
  const value = parseFloat(numStr);
  return value;
}

export default Vue.extend({
  components: { Loading, BlurredTenant },
  data() {
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      dataContainer: new DataContainer(),
      formOptions: {
        attrs: {
          title: "Time Zone",
        },
        content: {
          ignoreOtherFields: true,
          sections: {
            default: {
              fields: {
                timeZone: {
                  type: "v-autocomplete",
                  attrs: {
                    label: "Time Zone",
                    items: [
                      {
                        text: "System Time Zone",
                        value: "",
                      },
                    ].concat(
                      timeZones
                        .sort((a, b) => nornalizeTimeZone(a) - nornalizeTimeZone(b))
                        .map((item) => ({
                          text: `${item.utc} (${item.name})`,
                          value: item.abbr,
                        }))
                    ),
                  },
                },
              },
            },
          },
          actionButtons: {
            save: {
              content: {
                text: "Save",
              },
              on: {
                async xClick({ self }) {
                  const form = self.context();
                  const data = form.getData();
                  form.loading.value = true;
                  self.loading.value = true;
                  await coreApiClient.call(
                    "users",
                    "updateSetting",
                    {
                      id: userManager.getUserInfo()._id,
                    },
                    data
                  );
                  self.loading.value = false;
                  form.loading.value = false;
                  form.success.value = "Time zone saved successfully";
                },
              },
            },
          },
        },
      },
      formContainer: new DataContainer(),
    };
  },
  async created() {
    const data = await coreApiClient.call("users", "getSetting", {
      id: userManager.getUserInfo()._id,
    });
    this.dataContainer.value = data;
  },
});
